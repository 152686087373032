/* eslint-disable */
import React from 'react'



import Layout from '../components/Layout'
import NotFound from '../components/NotFound'

const NotFoundPage = () => {
  

  return (
    <div class="fourohfourd">
    <Layout>
      <NotFound />
    </Layout>
    </div>
  )
}

export default NotFoundPage


