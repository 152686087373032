/* eslint-disable */
import React from 'react'




const NotFound = () => {
  

  return (
    <div>
      not found page
    </div>
  )
}

export default NotFound

